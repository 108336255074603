<template>
	<main class="pt-0">
		<section>
			<BaseSearchInput v-model="searchText" placeholder="Sök namn eller födelsedatum">
				Sök namn eller födelsedatum
			</BaseSearchInput>
		</section>

		<section class="py-0" v-if="attendances">
			<div class="flex items-center justify-between gap-5 mb-20">
				<div class="flex items-center gap-5">
					<BaseButton>
						Markara som frånvarande
					</BaseButton>
					<BaseButton>
						Markera som närvarande
					</BaseButton>
				</div>

				<BaseButton transparent>
					Exportera
				</BaseButton>
			</div>

			<table class="list-table">
				<tr>
					<th class="w-1/4">Namn</th>
					<th class="w-1/4">Anmäld av</th>
					<th class="w-1/4">Födelsedatum</th>
					<th class="w-1/4">Status</th>
				</tr>

				<tr v-for="attendance in attendances" :key="attendance.id">
					<td>{{ attendance.first_name }} {{ attendance.last_name }}</td>
					<td v-if="attendance.applicant">{{ attendance.applicant.first_name }} {{ attendance.applicant.last_name }}</td>
					<td v-else>-</td>
					<td>{{ attendance.birthday }}</td>
					<td :class="attendanceStatusColor(attendance.status)">{{ attendanceStatus(attendance.status) }}</td>
				</tr>
			</table>

		</section>
	</main>
</template>

<script>
import basicApiCall from '@/core/helpers/basicApiCall'
import ApplicationService from '@/core/services/ApplicationService'
import {ATTENDANCE} from '@/core/types/ActivityTypes'

export default {
	name: 'ActivityPresence',
	data: () => ({
		searchText: '',
		attendances: null,
	}),
	methods: {
		attendanceStatus(status) {
			switch (status) {
				case ATTENDANCE.UNATTENDED:
					return 'Frånvarande'
				case ATTENDANCE.UNDEFINED:
					return '-'
				case ATTENDANCE.ATTENDED:
					return 'Närvarande'
			}
		},
		attendanceStatusColor(status) {
			switch (status) {
				case ATTENDANCE.UNATTENDED:
					return 'text-red-500'
				case ATTENDANCE.UNDEFINED:
					return '-'
				case ATTENDANCE.ATTENDED:
					return 'text-green-500'
			}
		},
		async fetchAttendances() {
			const {status, data} = await basicApiCall(ApplicationService.indexAttendance(this.$route.params.id))
			if (status === 200) {
				this.attendances = data
			}
		},
	},
	created() {
		this.fetchAttendances()
	},
}
</script>

<style scoped>

</style>